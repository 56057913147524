import React from 'react'
import { Link } from 'gatsby'
import Logo from '../images/getup-logo-navy.svg'
import '../styles/nav.scss'
import Modal from '../components/Modal'
import RecruitModal from '../components/RecruitModal'

class Nav extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isMenuActive: false,
      recruitmentModule: {
        pageId: 20667,
        moduleId: 49821
      },
      volunteerModule: {
        pageId: 20667,
        moduleId: 49826
      }
    }
  }
  handleMenuClick = () => {
    this.setState({ isMenuActive: !this.state.isMenuActive })
  }
  render () {
    // const donation = {
    //   buttonText: 'Donate',
    //   link:
    //   'https://www.getup.org.au/campaigns/climate-action-now/bushfire-donation-page/bsca-donation-page'
    // }
    return (
      <nav>
        <div className='row align-center'>
          <div className='small-12 medium-12 large-12 columns'>
            <div className='nav-container'>
              <ul>
                <li className='nav-logo--wrapper'>
                  <Link to='/' className='logo'>
                    <img src={Logo} alt='GetUp logo' />
                  </Link>
                </li>
              </ul>
              <ul>
                <li className='hide-in-small'>
                  <a href='https://www.getup.org.au/about'>About</a>
                </li>
                <li className='hide-in-small'>
                  <a href='https://www.getup.org.au/media'>Media</a>
                </li>
                <li className='hide-in-small'>
                  <RecruitModal
                    module={this.state.volunteerModule}
                    buttonText='Volunteer'
                    heading='Volunteer now!'
                    button='Volunteer now!'
                    className=''
                    notButton='true' />
                  {/* <Modal href='https://www.getup.org.au/campaigns/federal-election-2018-19/election-home/2019-election-volunteer' buttonText='Volunteer' notButton='true' className='' /> */}
                </li>
                <li className='hide-in-small'>
                  <RecruitModal
                    module={this.state.recruitmentModule}
                    buttonText='Subscribe'
                    heading='Join the campaign'
                    button='Count me in!'
                    className=''
                    notButton='true' />
                  {/* <Modal href='https://www.getup.org.au/campaigns/federal-election-2018-19/election-home/join-us' buttonText='Subscribe' notButton='true' className='' /> */}
                </li>
                <li className='hide-in-small'>
                  <Modal
                    href='https://www.getup.org.au/campaigns/federal-election-2018-19/election-home/donate'
                    buttonText='Donate'
                    className='nav-btn' />
                </li>
                <li>
                  {/* <DonateButton
                    href={donation.link}
                    buttonText={donation.buttonText}
                  /> */}
                </li>
                <li
                  className={`hamburger-menu ${
                    this.state.isMenuActive ? 'is-active' : ''
                  }`}
                  onClick={this.handleMenuClick}
                >
                  <span className='line' />
                  <span className='line' />
                  <span className='line' />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={`mobile-menu-container  ${
            this.state.isMenuActive ? 'is-active' : ''
          }`}
        >
          <div
            className={`mobile-menu ${
              this.state.isMenuActive ? 'is-active' : ''
            }`}
          >
            <ul>
              <li>
                <a href='https://www.getup.org.au/about'>About</a>
              </li>
              <li>
                <a href='https://www.getup.org.au/media'>Media</a>
              </li>
              <li>
                <RecruitModal
                  module={this.state.volunteerModule}
                  buttonText='Volunteer'
                  heading='Volunteer now!'
                  button='Volunteer now!'
                  className=''
                  notButton='true' />
                {/* <a href='http://action.getup.org.au/'>Volunteer</a> */}
              </li>
              <li>
                <RecruitModal
                  module={this.state.recruitmentModule}
                  buttonText='Subscribe'
                  heading='Join the campaign'
                  button='Count me in!'
                  className=''
                  notButton='true' />
              </li>
              <li>
                {/* <a href='#' className='btn btn-primary'>Donate</a> */}
                <Modal
                  href='https://www.getup.org.au/campaigns/federal-election-2018-19/election-home/donate'
                  buttonText='Donate'
                  className='btn btn-primary' />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

export default Nav

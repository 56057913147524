import React from 'react'

const Footer = () => (
  <footer>
    <div className='footer-wrapper'>
      <ul>
        <li className='footer-col-header'>
          <a href='https://getup.org.au/campaigns' rel='noopener noreferrer' target='_blank'>
            Campaigns
          </a>
        </li>
        <li>
          <a href='http://action.getup.org.au' rel='noopener noreferrer' target='_blank'>
            Volunteer
          </a>
        </li>
        <li>
          <a href='https://www.getup.org.au/campaign/first-nations-justice'>First Nations Justice</a>
        </li>
        <li>
          <a href='https://getup.org.au/campaign-pillars/economic-fairness/'>Economic Fairness</a>
        </li>
        <li>
          <a href='https://getup.org.au/campaign-pillars/environmental-justice/'>
            Environmental Justice
          </a>
        </li>
        <li>
          <a href='https://getup.org.au/campaign-pillars/human-rights/'>Human Rights</a>
        </li>
        <li>
          <a href='https://getup.org.au/campaign-pillars/democratic-integrity/'>
            Democratic Integrity
          </a>
        </li>

        <li>
          <a
            href='https://www.communityrun.org/petition/new?source=footer'
            rel='noopener noreferrer'
            target='_blank'
          >
            Start a Campaign
          </a>
        </li>
      </ul>
      <ul>
        <li className='footer-col-header'>
          <a href='https://getup.org.au/campaigns' rel='noopener noreferrer' target='_blank'>
            Social
          </a>
        </li>

        <li>
          <a href='https://www.facebook.com/getupaustralia' rel='noopener noreferrer' target='_blank'>
            <i className='fa fa-facebook-official' aria-hidden='true' /> Facebook
          </a>
        </li>
        <li>
          <a href='https://www.twitter.com/getup' rel='noopener noreferrer' target='_blank'>
            <i className='fa fa-twitter-square' aria-hidden='true' /> Twitter
          </a>
        </li>
        <li>
          <a href='https://www.youtube.com/user/getupaustralia' rel='noopener noreferrer' target='_blank'>
            <i className='fa fa-youtube-square' aria-hidden='true' /> YouTube
          </a>
        </li>
        <li>
          <a href='https://www.instagram.com/getup_australia/' rel='noopener noreferrer' target='_blank'>
            <i className='fa fa-instagram' aria-hidden='true' /> Instagram
          </a>
        </li>
      </ul>
      <ul>
        <li className='footer-col-header'>
          <a href='https://getup.org.au/about' rel='noopener noreferrer' target='_blank'>
            Info
          </a>
        </li>

        <li>
          <a href='https://www.getup.org.au/dashboard'>My GetUp</a>
        </li>
        <li>
          <a href='https://getup.org.au/about/faqs/'>FAQs</a>
        </li>
        <li>
          <a href='https://www.getup.org.au/about/contact-us'>Contact</a>
        </li>
        <li>
          <a href='https://www.getup.org.au/getup-is-hiring'>Work at GetUp!</a>
        </li>
        <li>
          <a href='https://www.getup.org.au/about/privacy-policy'>Privacy Policy</a>
        </li>
        <li>
          <a href='https://www.getup.org.au/unsubscribe'>Unsubscribe</a>
        </li>
      </ul>
      <ul>
        <li className='footer-col-header'>
          <a href='https://getup.org.au/donate' rel='noopener noreferrer' target='_blank'>
            Donate
          </a>
        </li>

        <li>
          <a href='https://getup.org.au/donate/'>Make a Donation</a>
        </li>
        <li>
          <a href='https://getup.org.au/donate/willpower/'>Gift in your Will</a>
        </li>
        <li>
          <a href='https://getup.org.au/donations-policy'>Donations Policy</a>
        </li>
        <li>
          <a href='https://getup.org.au/donations-disclosure'>Donations Disclosure</a>
        </li>
      </ul>
      <div className='acknowledgement'>
        <p>
          Our team acknowledges that we meet and work on the land of the Gadigal people of the Eora
          Nation. We wish to pay respect to their Elders — past, present and future — and
          acknowledge the important role all Aboriginal and Torres Strait Islander people continue
          to play within Australia and the GetUp community.
        </p>
        <p>
          WARNING: Aboriginal and Torres Strait Islander people are warned that this website may
          contain images or names of deceased persons.
        </p>
        <p>
          © {new Date().getFullYear()} GetUp! All rights reserved. Authorised by P. Oosting, GetUp
          Limited, 53 Reservoir St, Sydney NSW.
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
